import { HostType } from './theme'

type Link = {
  name: string
  href: string
}

type LinkGroup = {
  name: string
  hosts: HostType[]
} & ({ href: string; links?: undefined } | { links: Link[]; href?: undefined })

export const links: LinkGroup[] = [
  {
    name: 'education',
    links: [
      { name: 'knowledge_base', href: '/help/knowledge-base/' },
      { name: 'free_webinars', href: 'https://webinar.sellmonitor.com/' },
    ],
    hosts: ['sellmonitor'],
  },
  { name: 'features', href: '/#features', hosts: ['sellscreen', 'sellematics', 'uzum'] },
  { name: 'blog', href: '/articles/', hosts: ['sellematics'] },
  // { name: 'careers', href: '/careers/', hosts: ['sellematics'] },
  { name: 'pricing', href: '/#pricing', hosts: ['sellmonitor', 'sellscreen', 'uzum'] },
  { name: 'faq', href: '/#faq', hosts: ['sellscreen'] },
  {
    name: 'extension',
    href: 'https://chromewebstore.google.com/detail/sellmonitor/nlldhlipbgolknjpcihjdobjjngeejag',
    hosts: ['sellmonitor'],
  },
  { name: 'consulting', href: '/consulting/', hosts: ['sellmonitor'] },
  { name: 'partners', href: '/partners/', hosts: ['sellscreen'] },
  { name: 'about', href: '/about/', hosts: ['sellmonitor', 'sellscreen', 'sellematics'] },
]
